import React from "react";

function JerseyFooter() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-column footer-about">
                <h3 className="footer-title">About Hotel</h3>
                <p className="footer-about-text">
                  Welcome to the best five-star deluxe hotel in Nigeria. We have a wide range of luxury hotels best suited for your every need. 
                </p>
                <div className="" style={{padding:"20px 0"}}>
              <img src={require("../assets/images/logos/genesis_hotels.png")} className="logo-img" alt="" />
            </div>
              </div>
            </div>
            <div className="col-md-3 offset-md-1">
              <div className="footer-column footer-explore clearfix">
                <h3 className="footer-title">Explore</h3>
                <ul className="footer-explore-list list-unstyled">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/hotel">Our Hotels </a>
                  </li>
                  <li>
                    <a href="/blog">Our Blog</a>
                  </li>
                  {/* <li>
                    <a href="/restaurant">Restaurant</a>
                  </li>
                  <li>
                    <a href="/recreation">Recreation</a>
                  </li>
                  <li>
                    <a href="/digital">Digital Experience</a>
                  </li> */}
                  <li>
                    <a href="/gallery">Gallery</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-column footer-contact">
                <h3 className="footer-title">Contact</h3>
                <div className="footer-contact-text">
                  <h6  style={{color:"#fff"}}>Jersey Apartments</h6>
                    <p>4 OGIWOH CLOSE, OFF BIRABI STREET, GRA PHASE 2, PORT HARCOURT </p>
                </div>
                <div className="footer-contact-info">
                  <p className="footer-contact-phone"><span className="flaticon-call"> +234 908 761 0221 </span></p>
                  <p className="footer-contact-mail">reservation.phhotels@genesisgroupng.com</p>
                </div>
                <div className="footer-about-social-list">
                  <a href="https://www.instagram.com/genesishotelsphc/" target="_blank">
                    <i className="ti-instagram" />
                  </a>
                  <a href="https://facebook.com/Genesishotelsphc" target="_blank">
                    <i className="ti-facebook" />
                  </a>
                  <a href="https://wa.me/08050499247" target="_blank">
                    <i className="ti-headphone-alt" />
                  </a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-bottom-inner">
                <p className="footer-bottom-copy-right">
                  © Copyright {new Date().getFullYear()} by <a href="#">Genesis Hotels</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default JerseyFooter;
