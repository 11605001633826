import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { displayAmount, makeTitleCase } from "../utils/helpers";
import BookingModal from "./Modals/BookingModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SingleRoomTile({hotel, room, index, params = null}) {
  const [showBookingModal, setShowBookingModal] = useState(false)
  const handleBookNow  = (e) => {
    e.preventDefault();
    if(params){
      window.location = `/checkout?hotel=${hotel.slug}&room=${room.slug}&arrival=${params.arrival}&departure=${params.departure}&adult=${params.adult}&children=${params.children}`
    }
else{
setShowBookingModal(true)
}
  }
  let { currency } = useSelector(state=>state.app)
  let price = currency === "USD" ? displayAmount(room.priceUsd,0,"$") :  displayAmount(room.priceNgn)
  let promoPrice = currency === "USD" ? displayAmount(room.promoPriceUsd,0,"$") :  displayAmount(room.promoPriceNgn)
let isNotPromo = (promoPrice === "₦0" || promoPrice === "$0")
  const handleCheckout = (data) => {
    window.location = `/checkout?hotel=${hotel.slug}&room=${room.slug}&arrival=${data.arrival}&departure=${data.departure}&adult=${data.adult}&children=${data.children}`
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
    autoplay:true,
    autoplaySpeed:2000,
  };

  return (
    <>
    <div className={`rooms2 mb-90 animate-box ${index%2 == 1 ? "left":null}`} data-animate-effect="fadeInUp">
    <figure>
    <Slider {...settings}>
        {room.images?.map(image => 
            <img src={image} alt={room.name} className="img-fluid" />
          )}
    </Slider>
    </figure>
     {/* <figure>
            <img src={room.images[0]} alt={room.name} className="img-fluid" />
          </figure> */}
      <div className="caption">
        {
         isNotPromo && <h3 className="inline">{price}</h3>
        }
        { !isNotPromo && <>
        <h3 className="text-muted inline"><del>{price}</del> </h3>
        <div className="flex " style={{display:"flex"}}>
        <h3 className="inline"> {promoPrice}<span>/ Night</span></h3>
        <span className="badge badge-pill badge-default badge-dark text-white mx-1 pt-2" style={{fontSize:10}}> {hotel.slug === "genesis-reventon-park" ? "15%":hotel.slug === "genesis-jersey-estate"?"20%" : "10%"  }discount applied</span>
        </div>
        </>}
        <h4>
          <a href={params?`/room/${room.slug}?ref=${params.ref}&arrival=${params.arrival}&departure=${params.departure}&adult=${params.adult}&children=${params.children}` :`/room/${room.slug}`}>{makeTitleCase(room.name)}</a>
        </h4>
        <p>
          {room?.description}
        </p>
        <div className="row room-facilities">
          { room.name === "2 bedroom apartment" 
          ?  <>
          <div className="col-md-4">
            <ul>
              <li> 2-4 Persons  </li>
              <li>  Security</li>
              <li> Relaxation Area</li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
            <li>High speed WiFi </li>
              <li>Swimming Pool</li>
              <li> Constant Power</li>
            </ul>
          </div>
          <div className="col-md-4">
          <ul>
            <li>Working Kitchen </li>
              <li>Cooking Gas</li>
              <li>Backup Generator</li>
            </ul>
          </div>
            </>
            : room.name === "Penthouse" 
            ?
            <>
              <div className="col-md-4">
            <ul>
              <li> 2-4 Persons  </li>
              <li>  Security</li>
              <li> Relaxation Area</li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
            <li>High speed WiFi </li>
              <li>Swimming Pool</li>
              <li> Constant Power</li>
            </ul>
          </div>
          <div className="col-md-4">
          <ul>
            <li>Working Kitchen </li>
              <li>Cooking Gas</li>
              <li>Backup Generator</li>
            </ul>
          </div>
            </>
            : room.name === "3 bedroom apartment" 
           ? <>
            <div className="col-md-4">
            <ul>
              <li> 3-6 Persons  </li>
              <li>  Security</li>
              <li> Relaxation Area</li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
            <li>High speed WiFi </li>
              <li>Swimming Pool</li>
              <li> Constant Power</li>
            </ul>
          </div>
          <div className="col-md-4">
          <ul>
            <li>Working Kitchen </li>
              <li>Cooking Gas</li>
              <li>Backup Generator</li>
            </ul>
          </div>
          </>
          :
          <>
            <div className="col-md-4">
            <ul>
              <li>
                <i className="flaticon-group" /> 1-2 Persons
              </li>
              <li>
                <i className="flaticon-wifi" /> Gymnasium
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
              <li>
              <i className="flaticon-bed" /> Twin Bed
              </li>
              <li>
                <i className="flaticon-breakfast" /> Car Park
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
              <li>
                <i className="flaticon-clock-1" /> 200 sqft room
              </li>
             {hotel.slug != "genesis-place" && <li>
                <i className="flaticon-swimming" /> Swimming Pool
              </li>}
            </ul>
          </div>
          </>
          }
        
        </div>
        <hr className="border-2" />
        <div className="info-wrapper">
          <div className="more">
            <a href={params?`/room/${room.slug}?ref=${params.ref}&arrival=${params.arrival}&departure=${params.departure}&adult=${params.adult}&children=${params.children}` :`/room/${room.slug}`} className="link-btn" tabIndex={0}>
              See Details <i className="ti-arrow-right" />
            </a>
          </div>
          <div className="butn-dark" onClick={handleBookNow} style={{cursor:"pointer"}}>
            {" "}
            {/* <a href={`/room/${hotel.id}/${room.id}`} data-scroll-nav={1}> */}
            {/* <a href={`https://bookings.genesishotels.online/`} data-scroll-nav={1}> */}
              <span>Book Now</span>
            {/* </a>{" "} */}
          </div>
        </div>
      </div>
    </div>
    {showBookingModal &&  <BookingModal show={showBookingModal} close={()=>setShowBookingModal(false)} checkout={handleCheckout} />}
    </>
  );
}

export default SingleRoomTile;
